import './Servicos.css';

const Servicos = () => {

    const sectionsInfo = [
        {
            id: 1,
            h4: `Lanche coletivo: \n Ciclo 1, 2, 3 e 4 (manhã/tarde/esporte)`,
            p: '01 principal + 01 fruta + 01 suco (opcional)'
        },

        {
            id: 2,
            h4: 'Fundamental (manhã/esporte)',
            p: 'Kit 01: 01 suco + 01 principal',
            secondP: 'Kit 02: 01 suco + 01 principal +01 fruta'
        },

        {
            id: 3,
            h4: 'Almoço:',
            p: 'Almoço estilo self service + suco'
        }
    ]

    return (
        <section id='servicos' className='container__all'>
            <h2 className='h2__container servicos__h2--color'>Servicos</h2>

            <div className='servicos__sections--container secondContainer__all'>
                <div className='p__opacity servicos__opacity'></div>
                {sectionsInfo.map((item) => (
                    <section key={item.id} className='servicos__text--container'>
                        <h4 className='servicos__h4'>{item.h4}</h4>
                        <p className='servicos__p'>{item.p}</p>
                        <p className='servicos__p'>{item.secondP}</p>
                    </section>
                ))}

                <span className='servicos__span'>As opções ofertadas em cada serviço encontram-se na aba “cardápios”</span>
            </div>
        </section>
    )
}

export default Servicos;