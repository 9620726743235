import './Passo.css';

const Passo = () => {
    return (
        <section id='passo' className='container__all'>
            <h2 className='h2__container passo__h2--color'>Como contratar</h2>
            <div className='passo__container secondContainer__all'>
                <div className='p__opacity passo__opacity'></div>
                <div className='passo__p--container'>
                    <ol>
                        <li className='passo__li'>Leia com atenção o contrato;</li>
                        <li className='passo__li'>Preencha <span className='comoTratar-span'>individualmente</span> o formulário abaixo informando os serviços e os dias contratados;</li>
                        <li className='passo__li'>Realize o pagamento através do link que enviaremos por email ou pelo whatsapp;</li>
                        <li className='passo__li'>Envie o comprovante para o email ou whatsapp.</li>
                    </ol>

                    <p className='passo-obs'>OBS: Caso não saiba os dias que a criança participará, você pode selecionar a opção “crédito” e anexar o comprovante de Pix do valor que deseja deixar disponível.</p>

                    <div className='passo__btn--container'>
                        <a href='./images/contrato.pdf' className='passo__a' target='_blank'>Contrato</a>
                        <a href='https://docs.google.com/forms/d/16cCMhpytoj2eLMmrfHz13Pi_xIgbRxDtBDKF79_g7-Y/edit' className='passo__a' target='_blank'>Formulário</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Passo;