import Tabela from '../Tabela';
import './Valores.css';

const Valores = () => {

    return (
        <section id='valores' className='container__all'>
            <h2 className='h2__container valores__h2--color'>Valores</h2>
            <div className='valores__p--container secondContainer__all'>
                <Tabela />
            </div>
        </section>
    )
}

export default Valores;