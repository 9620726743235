import './Intro.css';

const Intro = () => {
    return (
        <section id='intro'>
            <p className='intro__p'>Comida saudável feita com criatividade e amor</p>
        </section>
    )
}

export default Intro;